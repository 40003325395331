import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/api';

export const loginThunk = createAsyncThunk(
  'userInfo/login',
  async ({ cb, ...data }) => {
    const response = await axios.post('sign-in-admin', data);
    return response.data;
  },
);

const loginThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const loginThunkFulfilled = (_, { payload }) => {
  payload.token && localStorage.setItem('token', payload.token);
  return {
    isConnected: true,
    isAuth: true,
    isLoading: false,
    error: null,
    ...payload,
  };
};

const loginThunkRejected = (state, { error }) => {
  state.isLoading = false;
  state.error = getErrorMessage(error.message);
};

export const getUserByTokenThunk = createAsyncThunk(
  'userInfo/getUserByToken',
  async (token) => {
    const response = await axios.get('/admin/me', { headers: { 'X-Access-Token': token }});
    return { ...response, token };
  },
);

const getUserByTokenThunkRejected = (state) => {
  state.isConnected = true;
  localStorage.removeItem('token');
};

export const userExtraReducers = (builder) => {
  builder
    .addCase(loginThunk.pending, loginThunkPending)
    .addCase(loginThunk.fulfilled, loginThunkFulfilled)
    .addCase(loginThunk.rejected, loginThunkRejected)
    .addCase(getUserByTokenThunk.fulfilled, loginThunkFulfilled)
    .addCase(getUserByTokenThunk.rejected, getUserByTokenThunkRejected);
};
