import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import SignIn from '../pages/SignIn/SignIn';

export const LOGOUT_ROUTES = [
  {
    path: '/sign-in',
    name: 'Home',
    element: <SignIn />,
  },
  {
    path: '*',
    element: <Navigate to="/sign-in" replace />,
  },
];

export const LOGIN_ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" replace />,
  },
];
