import React from 'react';
import { Popup } from '../../UI';

const ConfirmationPopup = ({ setConfirmDelete, setOpenModal }) => {
  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <Popup onClose={onClose}>
      <div className="confirm-popup">
        <div className="confirm-popup__title">
          Are you sure
        </div>
        <div className="confirm-popup__block">
          <button
            className="confirm-popup__btn confirm-popup__btn--cancel"
            type="button"
            onClick={() => {
              setConfirmDelete(false);
              setOpenModal(false);
            }} >
            Cancel
          </button>
          <button
            className="confirm-popup__btn confirm-popup__btn--accept"
            type="button"
            onClick={() => {
              setConfirmDelete(true);
            }}>
            Yes
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmationPopup;
