import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { selectEmails } from '../../../redux/slices/emails.slice';
import { createEmailThunk, deleteEmailThunk, getEmailsThunk } from '../../../redux/thunks/emails.thunk';
import ConfirmationPopup from '../../../components/Popup/ConfirmationPopup/ConfirmationPopup';

const DashboardCreateEmail = () => {
  const dispatch = useDispatch();
  const emails = useSelector(selectEmails);
  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ userId, setUserId ] = useState();
  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm();

  useEffect(() => {
    dispatch(getEmailsThunk());
  }, []);

  useEffect(() => {
    if (confirmDelete) {
      dispatch(deleteEmailThunk(userId))
        .unwrap()
        .then(() => {
          toast('Success', { type: 'success' });
          reset();
          setOpenModal(false);
          setConfirmDelete(false);
        })
        .catch(() => {
          toast('User not created, not strong password', { type: 'error' });
          setOpenModal(false);
          setConfirmDelete(false);
        })
        .finally(() => {
          setOpenModal(false);
          setConfirmDelete(false);
        });
    }
  }, [ confirmDelete ]);

  const onSubmit = (data) => {
    dispatch(createEmailThunk(data))
      .unwrap()
      .then(() => {
        toast('Success', { type: 'success' });
        reset();
      })
      .catch(() => {
        toast('Error', { type: 'error' });
      });
  };

  return (
    <div className="dashboard-create">
      <form className="dashboard-create__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="dashboard-create__line">
          <div className="dashboard-create__label">
            <div className="dashboard-create__name">
              Email
            </div>
            <input
              {...register('email', { required: true })}
              className={classNames('dashboard-create__input', 'default-input', { 'default-input--error': errors.email })}
              name="email"
              type="email"
            />
          </div>
          <div className="dashboard-create__submit">
            <button className="dashboard-create__submit-btn btn" type="submit">
              Create
            </button>
          </div>
        </div>
      </form>
      <ul className="dashboard-create__list dashboard-create__list--email">
        <li className="dashboard-create__item dashboard-create__item--header">
          <div className="dashboard-create__item-column dashboard-create__item-column--id">
            ID
          </div>
          <div className="dashboard-create__item-column dashboard-create__item-column--username">
            Email
          </div>
          <div className="dashboard-create__item-column dashboard-create__item-column--delete" />
        </li>
        {emails?.map(item => (
          <li className="dashboard-create__item" key={item.id}>
            <div className="dashboard-create__item-column dashboard-create__item-column--id">
              {item.id}
            </div>
            <div className="dashboard-create__item-column dashboard-create__item-column--username">
              {item.email}
            </div>
            <div className="dashboard-create__item-column dashboard-create__item-column--delete">
              <button
                className="dashboard-create__item-delete__btn"
                type="button"
                onClick={() => {
                  setOpenModal(true);
                  setUserId(item.id);
                }}>
                <span className="img-container">
                  <svg width="1rem" height="1rem">
                    <use xlinkHref="#trash" />
                  </svg>
                </span>
              </button>
            </div>
          </li>
        ))}
      </ul>
      { openModal
        && <ConfirmationPopup
          setConfirmDelete={setConfirmDelete}
          setOpenModal={setOpenModal}
      /> }
    </div>

  );
};

export default DashboardCreateEmail;
