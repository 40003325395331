import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/api';

export const getEmailsThunk = createAsyncThunk(
  'emails/get',
  async () => {
    const response = await axios.get('get-mails');
    return response.data;
  },
);

const getEmailsThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getEmailsThunkFulfilled = (state, { payload }) => {
  state.emails = payload;
};

const getEmailsThunkRejected = (state, { error }) => {
  state.isLoading = false;
  state.error = getErrorMessage(error.message);
};

export const createEmailThunk = createAsyncThunk(
  'emails/create',
  async (data, { dispatch }) => {
    const response = await axios.post('create-mail', data);
    dispatch(getEmailsThunk());
    return response.data;
  },
);

export const deleteEmailThunk = createAsyncThunk(
  'emails/delete',
  async (id, { dispatch }) => {
    const response = await axios.delete('delete-mail', {
      params: {
        ...(id ? { id } : {}),
      },
    });
    dispatch(getEmailsThunk());
    return response.data;
  },
);

export const emailsExtraReducers = (builder) => {
  builder
    .addCase(getEmailsThunk.pending, getEmailsThunkPending)
    .addCase(getEmailsThunk.fulfilled, getEmailsThunkFulfilled)
    .addCase(getEmailsThunk.rejected, getEmailsThunkRejected);
};
