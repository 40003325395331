import DashboardCreateEmail from '../pages/Dashboard/DashboardCreateEmail/DashboardCreateEmail';
import DashboardCreateUser from '../pages/Dashboard/DashboardCreateUser/DashboardCreateUser';

// CONSTANTS
export const TABS_NAMES = {
  CREATE_USER: 'Create User',
  ADD_EMAIL: 'Add Email',
};

export const TABS_LIST = Object.values(TABS_NAMES);

const { CREATE_USER, ADD_EMAIL } = TABS_NAMES;

// CONFIGS
export const TABS_CONFIGS = {
  [CREATE_USER]: DashboardCreateUser,
  [ADD_EMAIL]: DashboardCreateEmail,
};
