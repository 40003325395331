import user from './slices/user.slice';
import customers from './slices/customers.slice';
import emails from './slices/emails.slice';
import popup from './slices/popups.slice';

export const reducers = {
  user,
  customers,
  emails,
  popup,
};
