import { createSlice } from '@reduxjs/toolkit';
import { userExtraReducers } from '../thunks/user.thunk';

const initialState = {
  isConnected: false,
  isAuth: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsConnected: (state) => { state.isConnected = true; },
    setUser: (state, { payload }) => ({ ...state, ...payload, isAuth: true }),
    resetUser: () => {
      localStorage.removeItem('token');
      return {
        ...initialState,
        isConnected: true,
      };
    },
  },
  extraReducers: userExtraReducers,
});

// ACTIONS
export const { setIsConnected, setUser, resetUser } = userSlice.actions;

// SELECTORS
export const selectIsConnected = state => state.user.isConnected;
export const selectIsAuth = state => state.user.isAuth;

export default userSlice.reducer;
