import React from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { loginThunk } from '../../redux/thunks/user.thunk';

const SignIn = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors }} = useForm();

  const onSubmit = (data) => {
    dispatch(loginThunk(data))
      .unwrap()
      .then(() => {
        toast('Success', { type: 'success' });
      })
      .catch(() => {
        toast('Login or password incorrect', { type: 'error' });
      });
  };

  return (
    <div className="login">
      <div className="login-header">
        <div className="login-logo">
          <div className="img-container">
            <svg className="logo" width="29.3rem" height="5.375rem">
              <use xlinkHref="#logo" />
            </svg>
          </div>
        </div>
      </div>
      <div className="login-popup">
        <div className="login-popup__header">
          Login
        </div>
        <form className="login-popup__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-popup__block">
            <div className="login-popup__label">
              <div className="login-popup__name">
                Username
              </div>
              <input
                {...register('username', { required: true })}
                className={classNames('login-popup__input', { 'login-popup__input--error': errors.username })}
                type="text"
                name="username"
              />
            </div>
            <div className="login-popup__label">
              <div className="login-popup__name">
                Username
              </div>
              <input
                {...register('password', { required: true })}
                className={classNames('login-popup__input', { 'login-popup__input--error': errors.password })}
                type="password"
                name="password"
              />
            </div>
          </div>
          <div className="login-popup__accept">
            <button className="login-popup__accept-btn btn">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
