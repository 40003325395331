import { createSlice } from '@reduxjs/toolkit';
import { customersExtraReducers } from '../thunks/customers.thunk';

const initialState = {
  customers: [],
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setIsConnected: (state) => { state.isConnected = true; },
  },
  extraReducers: customersExtraReducers,
});

// ACTIONS
export const { setIsConnected } = customersSlice.actions;

// SELECTORS
export const selectCustomers = state => state.customers.customers;

export default customersSlice.reducer;
