import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LOGIN_ROUTES, LOGOUT_ROUTES } from '../configs/routes.configs';
import { selectIsAuth, selectIsConnected } from '../redux/slices/user.slice';
import { useSideEffects } from '../hooks/useSideEffects';
import Popups from './Popup/Popups';
import GlobalLoader from './Loader/GlobalLoader';

const App = () => {
  const isAuth = useSelector(selectIsAuth);
  const isConnected = useSelector(selectIsConnected);

  const routes = useRoutes(isAuth ? LOGIN_ROUTES : LOGOUT_ROUTES);

  useSideEffects();

  if (!isConnected) return <GlobalLoader />;

  return (
    <>
      {routes}
      <ToastContainer />
      <Popups />
    </>
  );
};

export default App;
