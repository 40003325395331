import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { resetUser } from '../../redux/slices/user.slice';
import { TABS_CONFIGS, TABS_LIST } from '../../configs/tabs.configs';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [ activeTab, setActiveTab ] = useState(TABS_LIST[0]);

  const ActiveComponent = TABS_CONFIGS[activeTab];

  return (
    <div className="dashboard">
      <div className="dashboard-content">
        <div className="dashboard-tab">
          {TABS_LIST.map(tab => (
            <button
              key={tab}
              className={classNames('dashboard-tab__btn',
                { 'dashboard-tab__btn--active': tab === activeTab })}
              type="button"
              onClick={() => setActiveTab(tab)}>
              {tab}
            </button>
          ))}
        </div>
        <ActiveComponent />
      </div>

      <div className="login-header">
        <div className="login-logo">
          <div className="img-container">
            <svg className="logo" width="29.3rem" height="5.375rem">
              <use xlinkHref="#logo" />
            </svg>
          </div>
        </div>
        <div className="dashboard-logout">
          <button className="dashboard-logout__btn" onClick={() => dispatch(resetUser())}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
