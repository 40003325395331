import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { createCustomerThunk, deleteCustomerThunk, getCustomersThunk } from '../../../redux/thunks/customers.thunk';
import { selectCustomers } from '../../../redux/slices/customers.slice';
import ConfirmationPopup from '../../../components/Popup/ConfirmationPopup/ConfirmationPopup';

const DashboardCreateUser = () => {
  const dispatch = useDispatch();
  const customers = useSelector(selectCustomers);
  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ userId, setUserId ] = useState();
  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm();

  useEffect(() => {
    dispatch(getCustomersThunk());
  }, []);

  useEffect(() => {
    if (confirmDelete) {
      dispatch(deleteCustomerThunk(userId))
        .unwrap()
        .then(() => {
          toast('Success', { type: 'success' });
          reset();
          setOpenModal(false);
          setConfirmDelete(false);
        })
        .catch(() => {
          toast('User not created, not strong password', { type: 'error' });
          setOpenModal(false);
          setConfirmDelete(false);
        })
        .finally(() => {
          setOpenModal(false);
          setConfirmDelete(false);
        });
    }
  }, [ confirmDelete ]);

  const onSubmit = (data) => {
    dispatch(createCustomerThunk(data))
      .unwrap()
      .then(() => {
        toast('Success', { type: 'success' });
        reset();
      })
      .catch(() => {
        toast('User not created, not strong password', { type: 'error' });
      });
  };

  return (
    <div className="dashboard-create">
      <form className="dashboard-create__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="dashboard-create__line">
          <div className="dashboard-create__label">
            <div className="dashboard-create__name">
              Username
            </div>
            <input
              {...register('username', { required: true })}
              className={classNames('dashboard-create__input', 'default-input', { 'default-input--error': errors.username })}
              type="text"
              name="username"
            />
          </div>
          <div className="dashboard-create__label">
            <div className="dashboard-create__name">
              Password
            </div>
            <input
              {...register('password', { required: true })}
              className={classNames('dashboard-create__input', 'default-input', { 'default-input--error': errors.password })}
              type="text"
              name="password"
            />
          </div>
          <div className="dashboard-create__submit">
            <button className="dashboard-create__submit-btn btn" type="submit">
              Create
            </button>
          </div>
        </div>
      </form>
      <ul className="dashboard-create__list dashboard-create__list--user">
        <li className="dashboard-create__item dashboard-create__item--header">
          <div className="dashboard-create__item-column dashboard-create__item-column--id">
            ID
          </div>
          <div className="dashboard-create__item-column dashboard-create__item-column--username">
            Username
          </div>
          <div className="dashboard-create__item-column dashboard-create__item-column--password">
            Password
          </div>
          <div className="dashboard-create__item-column dashboard-create__item-column--delete" />
        </li>
        {customers?.map(item => (
          <li className="dashboard-create__item" key={item.id}>
            <div className="dashboard-create__item-column dashboard-create__item-column--id">
              {item.id}
            </div>
            <div className="dashboard-create__item-column dashboard-create__item-column--username">
              {item.username}
            </div>
            <div className="dashboard-create__item-column dashboard-create__item-column--password">
              {item.password}
            </div>
            <div className="dashboard-create__item-column dashboard-create__item-column--delete">
              <button
                className="dashboard-create__item-delete__btn"
                type="button"
                onClick={() => {
                  setOpenModal(true);
                  setUserId(item.id);
                }}>
                <span className="img-container">
                  <svg width="1rem" height="1rem">
                    <use xlinkHref="#trash" />
                  </svg>
                </span>
              </button>
            </div>
          </li>
        ))}
      </ul>
      { openModal
        && <ConfirmationPopup
          setConfirmDelete={setConfirmDelete}
          setOpenModal={setOpenModal}
      /> }
    </div>
  );
};

export default DashboardCreateUser;
