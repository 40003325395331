import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import { POPUPS_IDS } from '../constants/popups.constants';

const {
  CONFIRMATION,
} = POPUPS_IDS;

export const POPUPS_CONFIGS = {
  [CONFIRMATION]: ConfirmationPopup,
};
