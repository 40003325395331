import { createSlice } from '@reduxjs/toolkit';
import { emailsExtraReducers } from '../thunks/emails.thunk';

const initialState = {
  emails: [],
};

export const emailsSlice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    setIsConnected: (state) => { state.isConnected = true; },
  },
  extraReducers: emailsExtraReducers,
});

// ACTIONS
export const { setIsConnected } = emailsSlice.actions;

// SELECTORS
export const selectEmails = state => state.emails.emails;
export const selectApproveDeleteStatus = state => state.emails.approveDeleteStatus;

export default emailsSlice.reducer;
