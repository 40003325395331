import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../helpers/api';

export const getCustomersThunk = createAsyncThunk(
  'customers/get',
  async () => {
    const response = await axios.get('getUsers');
    return response.data;
  },
);

const getCustomersThunkPending = (state) => {
  state.isLoading = true;
  state.error = null;
};

const getCustomersThunkFulfilled = (state, { payload }) => {
  state.customers = payload;
};

const getCustomersThunkRejected = (state, { error }) => {
  state.isLoading = false;
  state.error = getErrorMessage(error.message);
};

export const createCustomerThunk = createAsyncThunk(
  'customers/create',
  async (data, { dispatch }) => {
    const response = await axios.post('create-user', data);
    dispatch(getCustomersThunk());
    return response.data;
  },
);

export const deleteCustomerThunk = createAsyncThunk(
  'customers/delete',
  async (id, { dispatch }) => {
    const response = await axios.delete('delete-user', {
      params: {
        ...(id ? { id } : {}),
      },
    });
    dispatch(getCustomersThunk());
    return response.data;
  },
);

export const customersExtraReducers = (builder) => {
  builder
    .addCase(getCustomersThunk.pending, getCustomersThunkPending)
    .addCase(getCustomersThunk.fulfilled, getCustomersThunkFulfilled)
    .addCase(getCustomersThunk.rejected, getCustomersThunkRejected);
};
